export class Config {
  //public apiUrl = "http://localhost:8080/api/v1"

  // Production Api
  public apiUrl="https://api.closeville.com/api/v1"
  // Development Api
// public apiUrl ="http://18.223.145.228:8085/api/v1"
// public apiUrl="http://18.189.224.207:8085/api/v1"


// url = "http://18.223.145.228:3000" 
 //url = "http://18.189.224.207:3000" 
 
 url = "https://api.closeville.com"  

// new line
}
