
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Config } from "../config";
import 'rxjs/Rx';
declare var toastr : any

@Injectable()

export class LoginService{

    tempValue

    configUrl = new Config().apiUrl

    constructor(private http:Http){}

    activateClient(data){
        return this.http.post(this.configUrl + '/employee/activation' ,{employee:data}).pipe(map(res=> res.json()))
    }

    loginToApp(data){
        return this.http.post(this.configUrl + '/auth/login',data).pipe(map(res=> res.json()))
    }

    verifyOtp(data){
        return this.http.post(this.configUrl + '/auth/otpvalidation',data).pipe(map(res=> res.json()))
    }

    setValue(value){
        this.tempValue = value
        return
    }

    getValue(){
        return this.tempValue
    }

    verifyToken(token){ 
        // console.log("token token token",token);
        
        return this.http.post(this.configUrl + '/auth/verifyToken', {token:token}).pipe(map(res=> res.json())) 
    }

    forgotPassword(emailID){
        return this.http.post(this.configUrl + '/employee/forgetPassword',{email:emailID}).pipe(map(res => res.json()))
    }

    resetPassword(resetData){
        return this.http.post(this.configUrl + '/employee/resetPassword',resetData).pipe(map(res => res.json()))
    }

    logout(emailID){
        return this.http.post(this.configUrl + '/auth/logout',emailID).pipe(map(res => res.json()))
    }

}